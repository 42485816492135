import { CheckIcon } from "@heroicons/react/20/solid";

const includedFeatures = [
  "12 appointments free each month",
  "AI that learn your unique SOAP style",
  "Secure and encrypted note history",
  "Documentation done in seconds",
];

export default function Example() {
  return (
    <div id="price" className=" relative isolate bg-white py-24">
      {/* <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8"> */}
      <div
        className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
        aria-hidden="true"
      >
        <div
          className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#F67F0A] opacity-30"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl sm:text-center">
          <h2 className="text-4xl font-header tracking-tight text-gray-900 sm:text-6xl">
            Clinical Documentation for 88% less cost compared to Dragon One!
          </h2>
        </div>
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">
              Simple no-tricks pricing
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Dr.Doc AI system outperforms traditional clinical documentation
              tools available using natural language processing. - Letting
              physicians to focus on what matters most.
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-zinc-700">
                What&apos;s included
              </h4>
              <div className="h-px flex-auto bg-zinc-400" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    className="h-6 w-5 flex-none text-drDocBlue"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">
                  Early Access Pricing
                </p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    $35
                  </span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                    / month
                  </span>
                </p>
                <a
                  href="https://client.drdoc.ai/register"
                  target="_blank"
                  rel="noreferrer"
                  className="mt-10 block w-full rounded-md px-3 py-2 border  border-gray-800 hover:text-gray-900 bg-gray-900 text-white hover:bg-white text-center font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                >
                  Get Free Access
                </a>

                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company
                  reimbursement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
