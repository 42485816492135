import { NextSeo } from "next-seo";
import { createSEOPageConfig } from "../utils/seo";
import { configSet } from "../lib/pageConfig";
import { getAllFilesFrontMatter } from "../utils/mdx";
import { useState } from "react";
// COMPONENTS
import Head from "../components/head";
import Landing from "../components/landing";

export async function getStaticProps() {
  const articles = (await getAllFilesFrontMatter("blog")).sort(function (a, b) {
    return new Date(b.publishDate) - new Date(a.publishDate);
  });

  return { props: { articles } };
}

export default function Home({ articles = [] }) {
  return (
    <div className="font-copy bg-black min-h-screen">
      <Head />
      <NextSeo {...createSEOPageConfig(configSet.landing)} />
      <Landing />
    </div>
  );
}
