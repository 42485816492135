//COMPONENTS
import Base from "../container/base";
import Hero from "./hero";
import Features from "./features";
import Pricing from "./price";

export default function Home() {
  return (
    <div className="h-full w-full bg-white">
      <Base>
        <Hero />
        <Features />
        <Pricing />
      </Base>
    </div>
  );
}
