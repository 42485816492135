import React from "react";
import Link from "next/link";
import Marquee from "./marquee";

export default function Hero() {
  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
        <div className="mx-auto max-w-7xl pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-40">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <h1 className="text-5xl font-header tracking-tight text-gray-900 sm:text-7xl">
                  Docs for Docs: Making Paperwork Painless.
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Dr.Doc AI will transcribe and document your appointment notes
                  with SOAP style - in 3 seconds.
                </p>
                <div className="mt-10 flex flex-col md:flex-row items-center gap-x-6 gap-y-6 md:gap-y-0">
                  <a
                    href="https://client.drdoc.ai/register"
                    target="_blank"
                    rel="noreferrer"
                    className="w-max border border-gray-800 hover:text-gray-900 rounded-md px-6 py-2 bg-gray-900 text-white hover:bg-white"
                  >
                    Get Free Access Now
                  </a>
                  <Link
                    href="/demo"
                    className="font-medium w-max border-2 border-drDocBlue hover:text-gray-900 rounded-md px-6 py-2 bg-blue-50 text-drDocBlue hover:bg-gray-50"
                  >
                    Get a Demo
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <Icons /> */}
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-DrDocBlue-600/10 ring-1 ring-blue-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <Marquee />
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
      <div className="bg-drDocBlue">
        <div className="max-w-7xl mx-auto p-6 flex flex-row gap-4">
          <img src="/quote.png" className="h-5 w-5" alt="Quotation mark" />
          <p className="font-medium text-lg text-white">
            “Saves countless hours... [Dr.Doc AI] has greatly improved my
            patients clinic experience & freed up my time to spend with my
            family and friends.
            <span className="italic">- Shahbaz Malik, MD</span>
          </p>
        </div>
      </div>
    </div>
  );
}
