import Head from "next/head";

export default function HeadComponent() {
  return (
    <Head>
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/three.js/r134/three.min.js"
        async
      />
      <script
        src="https://cdn.jsdelivr.net/npm/vanta/dist/vanta.waves.min.js"
        async
      />
      <script src="https://embed.typeform.com/next/embed.js" async />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width"
      />
      {process.env.NODE_ENV === "production" && (
        <>
          <script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "duvu3rlmwn");`,
            }}
          />
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-30037DH1EH`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-30037DH1EH');
      `,
            }}
          />

          <script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments); },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js', a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script'); twq('config','ofdgw');`,
            }}
          />
        </>
      )}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon.ico" />
      <link rel="manifest" href="/site.webmanifest"></link>
    </Head>
  );
}
