import image from "../public/logo.png";

// eslint-disable-next-line import/prefer-default-export
export const config = {
  siteName: "Dr.Doc AI",
  blogTitle: "Blog",
  baseUrl: "drdoc.ai",
  websiteLogo: image,
  twitterHandle: "@junaid_builds",
  twitterCardType: "Dr.Doc AI Blog",

  css: {
    primaryColor: "#111",
    accentColor: "#455A64",
    lightGray: "#eeeeee",
    backgroundColor: "#fff",
    black: "#111",
    white: "#fff",
    textColor: "#333",
    opacity: 0.7,
  },
};

const privacy = {
  ...config,
  siteDescription: "Privacy policy for AI Doctor Documentation Tool ",
  defaultPageTitle: "Privacy | Dr.Doc AI",
};

const FAQ = {
  ...config,
  siteDescription: "Frequently asked questions for Dr.Doc AI",
  defaultPageTitle: "FAQ | Dr.Doc AI",
};

const landing = {
  ...config,
  siteDescription:
    "Dr.Doc AI will quickly transcribe and format your appointment notes with your exact format - in seconds.",
  defaultPageTitle: "AI Clinical Documentation | Dr.Doc AI ",
};

const thankYou = {
  ...config,
  siteDescription:
    "Dr.Doc AI will quickly transcribe and format your appointment notes with your exact format - in seconds.",
  defaultPageTitle: "Get In Touch | Dr.Doc AI",
};

const blogHome = {
  ...config,
  siteDescription:
    "Dr.Doc AI will quickly transcribe and format your appointment notes with your exact format - in seconds.",
  defaultPageTitle: "Blog | Dr.Doc AI",
};

export const configSet = {
  FAQ,
  privacy,
  landing,
  blogHome,
  thankYou,
};
